.keyword1 {
  background-color: rgb(218, 97, 81);
  color: white;
}

.keyword1:hover {
  background-color: rgb(230, 124, 110);
}

.keyword2 {
  background-color: rgb(55, 119, 238);
  color: white;
}

.keyword2:hover {
  background-color: rgb(95, 146, 240);
  color: white;
}

.keyword {
  border-radius: 4px;
  margin: 0px 2px;
  padding: 0px 2px;
  /*filter: drop-shadow(2px 2px 2px rgb(184, 184, 184));*/
  box-shadow: 2px 2px 4px rgb(184, 184, 184);
}

.keyline {
  border-radius: 4px;
  margin: 0px 2px;
  padding: 0px 2px;
  /*filter: drop-shadow(2px 2px 2px rgb(184, 184, 184));*/
  background-color: rgb(238, 238, 238);
  box-shadow: 2px 2px 4px rgb(184, 184, 184);
}

.keyline-element .keyline:hover {
  background-color: rgb(230, 230, 230);
}

.keyword-highlight {
  border-radius: 4px;
  border-width: 3px;
  border-color: rgb(70, 70, 70);
  border-style: dashed;
}