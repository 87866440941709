.App {
  text-align: center;
}

.block-editor {
  min-height: 900px;
}

.mind-map-top {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 980px;
  background-color: rgb(238, 238, 238);
}

#foot {
  min-height: 100px;
  width: 100%;
  background-color: rgb(73, 73, 73);
  color: white;
  box-shadow: 0px 0px 10px rgb(73, 73, 73);
  position: relative;
  z-index: 2000;
}

.drawer-link a {
  color: black;
  text-decoration: none;
}

::selection {
  background: #d3d3d3;
  color: #555;
}

::-moz-selection {
  background: #d3d3d3;
  color: #555;
}

::-webkit-selection {
  background: #d3d3d3;
  color: #555;
}

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: white;
  width: 10px;
  height: 10px;
  background-clip: padding-box;
}

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: white;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb:hover {
  background-color: #c9c9c9;
  border-radius: 10px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: rgb(204, 204, 204);
}

.dragwindow{
  -webkit-app-region: drag;
}

.nodrag{
  -webkit-app-region: no-drag;
}

@import '~antd/dist/antd.css';

@font-face {
  font-style: normal;
  font-family: 'Libre_Baskerville';
  src: url('https://zhishudali.ink/file/LibreBaskerville-Regular.ttf');
}

body{
  height: auto
}
html{
  width: 100%;
  height: 100%
}

