.code-area {
  background-color: #faf8f5;
  padding: 15px 15px 15px 0px;
  border-radius: 10px;
}

.CodeMirror {
  height: auto;
}

.CodeMirror-scroll {
  height: auto;
  overflow-y: hidden;
  overflow-x: auto;
}
