.math-editor-area {
  background-color: #ececec;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;

  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: #d4d4d4;
}
.math-editor-area-inline {
  background-color: #ececec;
  display: inline-block;

  min-width: 30px;
  margin-left: 10px;
  margin-right: 10px;

  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: #d4d4d4;
}
.math-editor-area-inline-tips {
  font-size: 12px;
  color: #747474;
  padding-bottom: 0px;

  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #d4d4d4;

  width: 40px;
  background-color: #f7f7f7;
  margin-right: 5px;
}
