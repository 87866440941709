/*marginLeft: "auto",
marginRight: "auto",
display: "block",
backgroundColor: "white",
boxShadow: "0px 0px 6px #b1b1b1 inset",
pointerEvents: "auto", */

.search-button {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    background-color: white !important;
    box-shadow: 0px 0px 6px #b1b1b1 inset;
    pointer-events: auto;
}

.search-button:hover {
    box-shadow: 0px 0px 12px #b1b1b1 inset;
}