.t-mind-canvas {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  margin: 10px 10px 10px 30px;
}
.t-mind-canvas-friends {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  margin: 5px 5px 5px 5px;
}

.t-mind-node {
  border-style: solid;
  border-width: 2px;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
}

.t-mind-map-canvas {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  pointer-events: none;
}

.t-main-mind-master {
  position: relative;
}

.t-default-line {
  stroke: rgb(160, 160, 160);
  fill: none;
}
