.title {
  font-size: 26px;
}
.pag {
  margin: 10px 0px;
  font-size: 20px;
}

.image {
  max-width: 100%;
  max-height: 20em;
  min-height: 10px;
}

.h1-s {
  /*font-size: 38px;
  margin-block-start: 20px;
  margin-block-end: 12px;*/
  font-size: 24px;
  margin-block-start: 0em;
  margin-block-end: 6px;
}
.h2-s {
  /*font-size: 32px;
  margin-block-start: 0em;
  margin-block-end: 10px;*/
  font-size: 24px;
  margin-block-start: 0em;
  margin-block-end: 6px;

  /*padding-left: 10px;*/
}
.h3-s {
  /*font-size: 28px;
  margin-block-start: 0em;
  margin-block-end: 8px;*/
  font-size: 24px;
  margin-block-start: 0em;
  margin-block-end: 6px;

  /*padding-left: 15px;*/
}
.h4-s {
  font-size: 24px;
  margin-block-start: 0em;
  margin-block-end: 6px;

  /*padding-left: 20px;*/
}
.h5-s {
  font-size: 20px;
  margin-block-start: 0em;
  margin-block-end: 4px;

  padding-left: 10px;
  padding-right: 10px;

  background-color: rgb(250, 238, 220);
  border-radius: 2px;
  border-color: burlywood;
  border-style: dashed;
  border-width: 1px;
}
.h6-s {
  font-size: 20px;
  margin-block-start: 0em;
  margin-block-end: 2px;

  padding-left: 10px;
  padding-right: 10px;

  background-color: rgb(233, 250, 220);
  border-radius: 2px;
  border-color: rgb(181, 222, 135);
  border-style: dashed;
  border-width: 1px;
}
